import React from 'react'

const formClasses =
'block w-full px-4 py-2 text-white placeholder-gray-400 border-none rounded-md shadow-sm appearance-none focus:outline-none focus:ring-qiwio-pink sm:text-sm bg-qiwio-field-bg placeholder:text-white/50'

const selectClass =
'block w-full py-2 pl-3 pr-10 mt-1 text-base border-none rounded-md focus:outline-none focus:ring-qiwio-pink sm:text-sm bg-qiwio-field-bg text-white'

function Label ({ id, children }) {
    return (
        <label htmlFor={id} className='block mb-2 text-sm font-medium text-white'>
            {children}
        </label>
    )
}
export function TextField({ id, label, type = 'text', className = '', placeholder, ...props }) {
  return (
    <div className={className}>
        {label && <Label id={id}>{label}</Label>}
        <div>
            <input id={id} type={type} {...props} className={formClasses} placeholder={placeholder} />
        </div>
    </div>
  )
}

export function SelectField({ id, label, className = '', defaultValue, ...props }) {
    return (
        <div className={className}>
            {label && <Label id={id}>{label}</Label>}
            <select id={id} {...props} className={selectClass} defaultValue={defaultValue} />
        </div>
    )
}