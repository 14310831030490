import React from "react"
import Seo from "../../components/seo"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { TextField, SelectField } from "../../components/base/fields"
import FreeTrialFeatures from "../../components/free-trial/free-trial-features"
const FreeTrial = () => {
  return (
    <div className="max-h-screen min-h-screen pt-8 overflow-hidden bg-qiwio-bg-black">
      <Seo title="Start your free trial of Qiwio" />

      <header className="z-50 flex justify-end px-20 translate-y-10 max-lg:justify-start max-lg:px-8">
        <Link to="/">
          <StaticImage
            src="../../images/logos/Qiwio Logo.svg"
            alt="Qiwio logo"
            loading="eager"
            width={100}
            placeholder="none"
          />
        </Link>
      </header>

      <main className="relative flex justify-center max-lg:mt-32">
        <div className="flex">
          <FreeTrialFeatures />

          <div className="min-w-[60vw] pl-20 my-auto -translate-y-12 max-lg:w-full max-lg:px-8 max-lg:translate-y-0">
            <h2 className="mb-2">Start your free trial</h2>
            <p className="mb-10">No credit card required</p>
            <div className="w-2/3 max-lg:w-full">
              <form
                className="space-y-6"
                method="POST"
                netlify-honeypot="bot-field"
                data-netlify="true"
                name="free trial form"
                action="/free-trial/confirmation"
              >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="free trial form" />

                <div className="flex gap-4">
                  <TextField
                    className="w-1/2"
                    label="First name*"
                    id="firstName"
                    name="firstName"
                    type="text"
                    autoComplete="first name"
                    placeholder="First name"
                    required
                  />
                  <TextField
                    className="w-1/2"
                    label="Last name*"
                    id="lastName"
                    name="lastName"
                    type="text"
                    autoComplete="last name"
                    placeholder="Last name"
                    required
                  />
                </div>
                <TextField
                  label="Email address*"
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder="Business email address"
                  required
                />
                <div className="flex gap-4">
                  <TextField
                    className="w-1/2"
                    label="Phone number*"
                    id="phoneNumber"
                    name="phoneNumber"
                    type="tel"
                    autoComplete="tel"
                    placeholder="Phone number"
                    required
                  />
                  <TextField
                    className="w-1/2"
                    label="Company name*"
                    id="companyName"
                    name="companyName"
                    type="text"
                    autoComplete="company name"
                    placeholder="Company name"
                    required
                  />
                </div>

                <div className="flex gap-4">
                  <SelectField
                    className="w-1/2"
                    label="Which of these best describes you?*"
                    id="jobRole"
                    name="jobRole"
                    required
                  >
                    <option disabled selected value>
                      {" "}
                      -- select an option --{" "}
                    </option>
                    <option value="CEO">CEO</option>
                    <option value="Head of eCommerce">Head of eCommerce</option>
                    <option value="Head of marketing">Head of marketing</option>
                    <option value="Head of sales">Head of sales</option>
                    <option value="Head of digital">Head of digital</option>
                    <option value="Marketing professional">
                      Marketing professional
                    </option>
                    <option value="Sales professional">
                      Sales professional
                    </option>
                    <option value="Intern">Intern</option>
                    <option value="Student">Student</option>
                    <option value="Other">Other</option>
                  </SelectField>
                  <SelectField
                    className="w-1/2"
                    label="How large is your organization?*"
                    id="companySize"
                    name="companySize"
                    required
                  >
                    <option disabled selected value>
                      {" "}
                      -- select an option --{" "}
                    </option>
                    <option value="0-10">0-10</option>
                    <option value="11-50">11-50</option>
                    <option value="51-100">51-100</option>
                    <option value="101-250">101-250</option>
                    <option value="251-500">251-500</option>
                    <option value="500+">500+</option>
                  </SelectField>
                </div>

                <SelectField
                  label="Subscribe to Qiwio's newsletter?*"
                  id="newsletter"
                  name="newsletter"
                  required
                >
                  <option disabled selected value>
                    {" "}
                    -- select an option --{" "}
                  </option>
                  <option>
                    Yes, I want to be the first one to hear about upcoming
                    events, blog posts and latest features
                  </option>
                  <option>I don't want to subscribe</option>
                </SelectField>

                <div>
                  <button
                    type="submit"
                    className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white duration-300 border border-transparent rounded-md shadow-sm focus:outline-none bg-qiwio-pink hover:-translate-y-1"
                  >
                    Get started
                  </button>
                </div>

                <p className="text-sm">
                  Qiwio needs the contact information you provide to us to
                  contact you about our products and services. You may
                  unsubscribe from these communications at any time. For
                  information on how to unsubscribe, as well as our privacy
                  practices and commitment to protecting your privacy, please
                  review our{" "}
                  <Link to="/legal/privacy" className="text-qiwio-pink">
                    Privacy Policy
                  </Link>
                  .
                </p>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default FreeTrial
